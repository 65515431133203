import { gql } from '@apollo/client';

import { WidgetType } from '../../types/widget';

const WIDGET_FIELDS = `
	id
	name
	type
	x
	y
	width
	height
	configuration
`;

// QUERIES

export type FetchWidgetsType = {
	widgets: WidgetType[];
};

export const FETCH_WIDGETS = gql`
	query Widgets {
		widgets {
			${WIDGET_FIELDS}
		}
	}
`;
