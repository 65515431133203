import { useQuery } from '@apollo/client';
import { Typography, styled } from '@mui/material';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { FETCH_WIDGETS, FetchWidgetsType } from '../api/widgets';
import Dashboard from '../components/Dashboard';
import { useSession } from '../components/SessionProvider';
import WidgetImagesProvider from '../settings/widgets/provider/WidgetImagesProvider';

const InfoWrapper = styled('div')(({ theme: { spacing } }) => ({
	height: '100%',
	width: '100%',

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: spacing(2),
}));

const Index = () => {
	const { customerFile: sessionCustomerFile } = useSession();

	const { data: widgetData, loading } = useQuery<FetchWidgetsType>(FETCH_WIDGETS);

	const { widgets } = widgetData ?? {};

	const widgetWithKeys = useMemo(
		() =>
			widgets?.map((widget) => ({
				key: uuid(),
				widget,
			})),
		[widgets],
	);

	return !sessionCustomerFile ? (
		<InfoWrapper>
			<Typography variant="h6">Aucun dossier</Typography>
			<Typography variant="body2">Aucun dossier client n&apos;est disponible</Typography>
		</InfoWrapper>
	) : (
		<WidgetImagesProvider>
			<Dashboard widgetsWithKey={widgetWithKeys} loading={loading} />
		</WidgetImagesProvider>
	);
};

export default Index;
